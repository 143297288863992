<template>
  <!-- pill tabs inside card -->
  <b-card>
    <div class="d-flex align-items-center">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-secondary"
        @click="$router.back()"
      >
        <feather-icon
          class="mr-25"
          icon="ChevronLeftIcon"
        />
      </b-button>
      <h3 class="mb-0 ml-2 d-flex justify-content-between">
        <span>{{ student.full_name }}</span>
      </h3>
    </div>
    <hr>
    <b-tabs
      v-model="activeTab"
      nav-class="mb-3"
      class="h-scroll-tabs"
      pills
      @input="updateHash"
    >
      <b-tab
        class="text-left"
        href="Mentors"
        title="Mentors"
        lazy
      >
        <Mentors />
      </b-tab>
      <b-tab
        class="text-left"
        href="Sessions"
        title="Sessions"
        lazy
      >
        <Emeet />
      </b-tab>
      <b-tab
        class="text-left"
        href="Programs"
        title="SDPs"
        lazy
      >
        <Programs />
      </b-tab>

      <b-tab
        class="text-left"
        href="Events"
        title="Events"
        lazy
      >
        <Events />
      </b-tab>
      <b-tab
        class="text-left"
        href="Competitions"
        title="Competitions"
        lazy
      >
        <Competitions />
      </b-tab>

      <b-tab
        class="text-left"
        href="Requests"
        title="Requests"
        lazy
      >
        <Requests />
      </b-tab>
      <b-tab
        class="text-left"
        href="Feedbacks"
        title="Feedbacks"
        lazy
      >
        <Feedbacks />
      </b-tab>

    </b-tabs>
  </b-card>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue'

import Requests from '@/views/incubator-portal/students/pages/student-manager/Requests.vue'
import Programs from '@/views/incubator-portal/students/pages/student-manager/Programs.vue'
import Emeet from '@/views/incubator-portal/students/pages/student-manager/Emeet.vue'
import Events from '@/views/incubator-portal/students/pages/student-manager/Events.vue'
import Competitions from '@/views/incubator-portal/students/pages/student-manager/Competitions.vue'
import Feedbacks from '@/views/incubator-portal/students/pages/student-manager/Feedback.vue'
import Mentors from '@/views/incubator-portal/students/pages/student-manager/Mentors.vue'
import gql from 'graphql-tag'

export default {
  components: {
    Emeet,
    Programs,
    Requests,
    Feedbacks,
    Competitions,
    Events,
    Mentors,
    BTab,
    BTabs,
  },
  data() {
    return {
      activeTab: 0,
      tabs: [
        '#Mentors',
        '#Sessions',
        '#Programs',
        '#Events',
        '#Competitions',
        '#Requests',
        '#Feedbacks',
      ],
      student: {},
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.activeTab = this.tabs.findIndex(tab => tab === this.$route.hash)
    })
  },
  methods: {
    updateHash(e) {
      this.$router.replace(this.$route.path + this.tabs[e])
    },
  },
  apollo: {
    student: {
      query() {
        return gql`
           query about_student {
           users_customuser(where: {users_associationtables: {id: {_eq: ${this.$route.params.id} }}}) {
             full_name
          }
        }`
      },
      update: data => data.users_customuser[0],
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
